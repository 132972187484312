<template>
  <el-scrollbar
    v-loading="props.loading"
    max-height="calc(100vh - 320px)"
    wrap-class="elv-account-dialog-content-scrollbar__wrap"
  >
    <el-form
      ref="accountFormRef"
      class="elv-account-dialog-form"
      :model="accountForm"
      :rules="rules"
      label-position="top"
    >
      <el-form-item
        v-if="props.platformData.type === 'CHAIN'"
        :label="
          ['bitcoin'].includes(props.platformData?.slug) ? t('label.addressOrXpubRrecommend') : t('common.address')
        "
        prop="identity"
      >
        <el-input
          v-model.trim="accountForm.identity"
          :placeholder="
            ['bitcoin'].includes(props.platformData?.slug) ? t('label.addressOrXpubRrecommend') : t('common.address')
          "
          :disabled="props.model !== 'add'"
        />
      </el-form-item>

      <el-form-item v-if="props.platformData.type === 'BANK'" :label="t('report.accountNumber')" prop="identity">
        <el-input v-model="accountForm.identity" :disabled="props.model === 'edit'" />
      </el-form-item>

      <el-form-item :label="t('common.name')" prop="name">
        <el-input v-model="accountForm.name" :placeholder="t('common.name')" />
      </el-form-item>

      <el-form-item
        v-if="props.platformData?.enableMasterSubAccount"
        :label="t('common.accountType')"
        prop="accountType"
      >
        <el-radio-group v-model="accountForm.accountLevel" class="elv-accounts-dialog-form-account-level-select">
          <el-radio value="MASTER">{{ t('common.masterAccount') }}</el-radio>
          <el-radio value="SUB">{{ t('common.subAccount') }}</el-radio>
        </el-radio-group>
        <ElvSelect
          v-if="accountForm.accountLevel === 'SUB'"
          v-model="accountForm.masterEntityAccountId"
          class="elv-accounts-dialog-form-master-account-select"
          :options="masterAccountOptions"
          clearable
          width="572px"
          :placeholder="t('placeholder.chooseMasterAccount')"
        />
      </el-form-item>

      <el-form-item v-if="props.model === 'add'" :label="t('common.dataIntegration')" prop="providerId">
        <el-select
          v-model="accountForm.providerId"
          placeholder=" "
          popper-class="elv-accounts-dialog-popper"
          :disabled="
            (props.platformData.collectorDriver !== 'CUSTOM' &&
              props.platformData.collectorDriver !== 'OFFICIAL' &&
              props.platformData.csvDriver !== 'CUSTOM' &&
              props.platformData.csvDriver !== 'OFFICIAL') ||
            props.platformData.csvDriver === 'NONE' ||
            props.platformData.collectorDriver === 'NONE'
          "
          @change="onChangeDataIntegration"
        >
          <el-option
            v-for="(item, index) in dataIntegrationOptions"
            :key="index"
            :label="item.provider?.name"
            :value="item.providerId"
          >
            <img :src="item?.provider?.logo" :alt="item?.provider?.name" />
            {{ item.provider.name }}
            <span class="elv-accounts-data-select-integration-info">{{ integrationInfo(item).value }}</span>
          </el-option>
          <template #prefix>
            <img
              v-if="currentProvider?.logo"
              :src="currentProvider?.logo"
              :alt="currentProvider?.name"
              class="elv-accounts-data-select-integration-icon"
            />
          </template>
        </el-select>
        <el-checkbox
          v-if="currentPlatformProviderMap?.withTransaction && currentPlatformProviderMap?.withBalance"
          v-model="accountForm.autoVerify"
          class="elv-accounts-data-checkbox"
          >Auto verify balance after sync</el-checkbox
        >
        <template v-if="accountForm.type === 'CSV'">
          <p class="elv-accounts-data-select-content-csv__tips">
            {{ t('message.addAccountCSVSourceInfo') }}
          </p>
          <p v-if="props.platformData.note?.CSV" class="elv-accounts-data-select-content-csv__tips">
            {{ props.platformData.note?.CSV }}
          </p>
        </template>
        <template
          v-if="currentPlatformProviderMap?.authType !== null && thirdPartyOpenAuthTypeData.status !== 'COMPLETED'"
          #error
        >
          <div class="elv-account-data-integration-error is-error">
            <SvgIcon name="warning-emptyBg" width="16px" height="16px" fill="#EB3A30" />
            Auth failed, please retry
          </div>
        </template>

        <div
          v-if="currentPlatformProviderMap?.authType !== null && thirdPartyOpenAuthTypeData.status === 'COMPLETED'"
          class="elv-account-data-integration-error is-success"
        >
          <SvgIcon name="two-factor-authentication" width="16px" height="16px" />
          Authed
        </div>
      </el-form-item>
      <template v-if="props.model === 'add' && currentPlatformProviderMap?.config?.length">
        <el-form-item
          v-for="item in currentPlatformProviderMap?.config ?? []"
          :key="item.key"
          :label="item.title"
          :prop="item.key"
        >
          <el-input
            v-if="item.type === 'input'"
            v-model.trim="accountForm[item.key]"
            :minlength="item?.config?.validator?.length?.min ?? '—'"
            :maxlength="item?.config?.validator?.length?.max ?? '—'"
            :placeholder="item?.config?.placeholder ?? ''"
          />
          <el-input
            v-else-if="item.type === 'textarea'"
            v-model.trim="accountForm[item.key]"
            :minlength="item?.config?.validator?.length?.min ?? '—'"
            :maxlength="item?.config?.validator?.length?.max ?? '—'"
            :autosize="{ minRows: 2 }"
            type="textarea"
            class="elv-accounts-data-secret-textarea"
            :placeholder="item?.config?.placeholder ?? ''"
          />
          <el-checkbox-group
            v-else-if="item.type === 'checkbox'"
            v-model="accountForm[item.key]"
            class="elv-accounts-data-item-checkbox"
          >
            <el-checkbox
              v-for="(option, index) in item?.config?.options"
              :key="index"
              :checked="option?.checked ?? false"
              :label="option.label"
              :value="option.value"
            />
          </el-checkbox-group>
        </el-form-item>
      </template>
      <div v-if="accountForm.type !== 'CSV' && props.model === 'add'" class="elv-accounts-data-select-content period">
        <div class="elv-accounts-data-select-period-container">
          <el-form-item :label="t('common.dataPeriod')" prop="transactionOption.operator">
            <el-select
              v-model="accountForm.transactionOption.operator"
              placeholder=" "
              popper-class="elv-accounts-dialog-popper"
              :class="{
                'elv-accounts-data-short-select':
                  accountForm.transactionOption.operator !== 'ALL' &&
                  accountForm.transactionOption.operator !== 'BETWEEN',
                'elv-accounts-data-shorter-select': accountForm.transactionOption.operator === 'BETWEEN'
              }"
              @change="onChangeOperator"
            >
              <el-option
                v-for="(item, index) in transactionDataOptions"
                :key="index"
                :label="transformI18n(item.label)"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="accountForm.transactionOption.operator !== 'ALL'"
            class="elv-accounts-form-item-date"
            prop="value"
          >
            <div
              ref="dateStartSelectRef"
              v-click-outside="onCloseStartDateTime"
              class="elv-accounts-date-select"
              :class="{
                'is-short': accountForm.transactionOption.operator === 'BETWEEN',
                'is-focus': visibleStartPopover
              }"
              @click="onOpenStartDateTime()"
            >
              <div v-if="accountForm.transactionOption.value[0]" class="elv-accounts-date-time-value">
                <span>{{
                  accountForm.transactionOption.value[0]
                    ? dateFormatting(accountForm.transactionOption.value[0], 'date', '-')
                    : ''
                }}</span>
              </div>
              <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: startArrowRotate }" />
            </div>

            <el-date-picker
              ref="datePickerStartPopoverRef"
              v-model="accountForm.transactionOption.value[0]"
              placement="bottom-start"
              popper-class="elv-accounts-date-popper"
              :virtual-ref="dateStartSelectRef"
              virtual-triggering
              type="date"
              value-format="YYYY-MM-DD"
              :clearable="false"
              @change="onChangeDate('start')"
            />
            <template v-if="accountForm.transactionOption.operator === 'BETWEEN'">
              <span class="elv-accounts-date-scope">{{ t('common.to') }}</span>
              <div
                ref="dateEndSelectRef"
                v-click-outside="onCloseEndDateTime"
                class="elv-accounts-date-select is-short"
                :class="{ 'is-focus': visibleEndPopover }"
                @click="onOpenEndDateTime"
              >
                <div v-if="accountForm.transactionOption.value[1]" class="elv-accounts-date-time-value">
                  <span>{{
                    accountForm.transactionOption.value[1]
                      ? dateFormatting(accountForm.transactionOption.value[1], 'date', '-')
                      : ''
                  }}</span>
                </div>
                <SvgIcon name="events-arrow" width="20" height="20" :style="{ transform: endArrowRotate }" />
              </div>

              <el-date-picker
                ref="datePickerEndPopoverRef"
                v-model="accountForm.transactionOption.value[1]"
                placement="bottom-start"
                popper-class="elv-accounts-date-popper"
                :virtual-ref="dateEndSelectRef"
                virtual-triggering
                type="date"
                value-format="YYYY-MM-DD"
                :clearable="false"
                @change="onChangeDate('end')"
              />
            </template>
          </el-form-item>
        </div>
        <p
          v-if="props.platformData.note?.COLLECTOR || props.platformData.note?.CSV"
          class="elv-accounts-data-select-content-period__tips"
        >
          {{ accountForm.type === 'CSV' ? props.platformData.note?.CSV : props.platformData.note?.COLLECTOR }}
        </p>
        <p
          v-if="
            (accountForm.transactionOption.operator === 'BETWEEN' ||
              accountForm.transactionOption.operator === 'AFTER') &&
            props.platformData.slug === 'ethereum'
          "
          class="elv-accounts-data-select-content-period__tips"
        >
          {{ t('message.addEthereumWalletInfo') }}
        </p>
      </div>
      <el-form-item :label="t('report.auxiliaryCode')">
        <div
          v-if="!showAuxiliaryCodeSelect"
          class="elv-ledger-coa-form-item__auxiliaryCode-add"
          @click="onAddAuxiliaryCode"
        >
          <SvgIcon name="add-default" width="16" height="16" fill="#1753eb" />{{ t('button.addAuxiliaryCode') }}
        </div>
        <template v-else>
          <div
            v-for="auxiliaryValue in accountForm.auxiliaryValueList"
            :key="auxiliaryValue.index"
            class="elv-account-data-item-auxiliary-code__item"
          >
            <el-select
              v-model="auxiliaryValue.auxiliaryTypeId"
              filterable
              :placeholder="`${t('common.select')}...`"
              popper-class="elv-accounts-dialog-popper"
              class="elv-account-data-item-auxiliary-code-select"
              @change="onChangeAuxiliaryType(auxiliaryValue.auxiliaryTypeId, auxiliaryValue.index)"
            >
              <el-option
                v-for="auxiliaryCodeOption in auxiliaryCodeOptionData.list"
                :key="auxiliaryCodeOption.auxiliaryTypeId"
                :label="auxiliaryCodeOption.name"
                :disabled="
                  accountForm.auxiliaryValueList.length &&
                  accountForm.auxiliaryValueList.some(
                    (data: any) => data.auxiliaryTypeId === auxiliaryCodeOption.auxiliaryTypeId
                  )
                "
                :value="auxiliaryCodeOption.auxiliaryTypeId"
              />
            </el-select>

            <el-select
              v-if="!auxiliaryValue.auxiliaryTypeId && !auxiliaryValue.type"
              class="elv-account-data-item-auxiliary-code-select"
              placeholder=" "
              :disabled="true"
            />

            <template v-else>
              <el-select
                v-if="auxiliaryValue.type === 'COUNTERPARTY'"
                v-model="auxiliaryValue.counterpartyId"
                filterable
                remote
                clearable
                reserve-keyword
                class="elv-account-data-item-auxiliary-code-select"
                :loading="searchCounterpartyLoading"
                :remote-method="remoteCounterpartyMethod"
                :placeholder="t('placeholder.enterNameForSearch')"
                popper-class="elv-accounts-dialog-popper"
              >
                <el-option
                  v-for="counterparty in counterpartyOptions"
                  :key="counterparty.counterpartyId"
                  :label="counterparty.name"
                  :value="counterparty.counterpartyId"
                />
              </el-select>

              <el-select
                v-else-if="auxiliaryValue.type === 'AUXILIARY_ITEM'"
                v-model="auxiliaryValue.auxiliaryItemId"
                filterable
                remote
                clearable
                class="elv-account-data-item-auxiliary-code-select"
                :placeholder="`${t('common.select')}...`"
                popper-class="elv-accounts-dialog-popper"
              >
                <el-option
                  v-for="auxiliaryItem in auxiliaryValue?.auxiliaryItems"
                  :key="auxiliaryItem.auxiliaryItemId"
                  :label="auxiliaryItem.value"
                  :value="auxiliaryItem.auxiliaryItemId"
                />
              </el-select>
            </template>

            <SvgIcon
              name="sources-delete"
              width="18"
              height="18"
              fill="#838D95"
              class="elv-account-data-item-auxiliary-code-select__delete"
              @click="onDeleteAuxiliaryCode(auxiliaryValue.index)"
            />
          </div>
          <div class="elv-ledger-coa-form-item__auxiliaryCode-add" @click="onAddAuxiliaryCode">
            <SvgIcon name="add-default" width="16" height="16" fill="#1753eb" />{{ t('button.addAuxiliaryCode') }}
          </div>
        </template>
      </el-form-item>
      <el-form-item :label="t('report.memo')">
        <el-input
          v-model="accountForm.memo"
          :rows="3"
          type="textarea"
          :placeholder="t('placeholder.descriptionAccount')"
        />
      </el-form-item>
    </el-form>
  </el-scrollbar>
  <div class="el-dialog__footer">
    <elv-button
      v-if="currentPlatformProviderMap?.authType !== null && thirdPartyOpenAuthTypeData.status !== 'COMPLETED'"
      type="primary"
      round
      height="44"
      width="174"
      class="elv-accounts-dialog-footer__button"
      :loading="authLoading"
      @click="applyOpenAuthInfo"
      >{{ `Auth on ${currentProvider?.name?.split(' (Default)')?.[0]}` }}</elv-button
    >

    <elv-button
      v-else
      type="primary"
      round
      height="44"
      width="113"
      class="elv-accounts-dialog-footer__button"
      :loading="submitLoading"
      :disabled="disabledSave"
      @click="onClickConnect"
      >{{ t('button.save') }}</elv-button
    >
  </div>

  <SourceAuthDialog
    v-model:show="showSourceAuthDialog"
    :title="`Auth ${currentProvider?.name?.split(' (Default)')?.[0]}`"
    :thirdPartyOpenAuthTypeData="thirdPartyOpenAuthTypeData"
  />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import AccountsApi from '@/api/AccountsApi'
import { dateFormatting } from '@/lib/utils'
import { transformI18n } from '@/i18n/index'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { transactionDataOptions } from '@/config/reports'
import { useEntityStore } from '@/stores/modules/entity'
import { ThirdPartyOpenAuthType } from '#/AccountsTypes'
import { useComputedHook } from '@/hooks/useComputedHook'
import type { FormInstance, FormRules } from 'element-plus'
import { useAccountStore } from '@/stores/modules/accounts'
import SourceAuthDialog from '../../components/SourceAuthDialog.vue'
import { find, trim, isEmpty, pick, cloneDeep, uniqBy } from 'lodash-es'
import { ElMessage, ClickOutside as vClickOutside } from 'element-plus'
import { PlatformType, PlatformProviderType, PlatformProviderMapType } from '#/EntityTypes'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  title: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  showDialog: {
    type: Boolean,
    default: false
  },
  platformData: {
    type: Object as () => PlatformType,
    required: true
  },
  counterpartyList: {
    type: Array,
    default: () => []
  },
  auxiliaryCodeOptionData: {
    type: Object,
    default: () => {
      return {
        list: [],
        total: 0
      }
    }
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const accountForm: any = reactive({
  type: 'EXCHANGE',
  platformId: '',
  name: '',
  memo: '',
  providerId: '',
  autoVerify: false,
  transactionOption: {
    operator: 'ALL',
    value: []
  },
  auxiliaryValueList: [],
  accountLevel: '',
  masterEntityAccountId: ''
})

const route = useRoute()
const datePickerStartPopoverRef = ref()
const datePickerEndPopoverRef = ref()
const { accountList } = toRefs(accountStore)
const dateStartSelectRef = useTemplateRef<any>('dateStartSelectRef')
const dateEndSelectRef = useTemplateRef<any>('dateEndSelectRef')
const accountFormRef = useTemplateRef<FormInstance>('accountFormRef')

const submitLoading = ref(false)
const showSourceAuthDialog = ref(false)
const showAuxiliaryCodeSelect = ref(false)
const visibleStartPopover = ref(false)
const visibleEndPopover = ref(false)
const searchCounterpartyLoading = ref(false)
const authLoading = ref(false)
const startArrowRotate = ref('rotate(90deg)')
const endArrowRotate = ref('rotate(90deg)')
const counterpartyOptions: any = ref([])
const originCounterpartyOptions: any = ref([])

const thirdPartyOpenAuthTypeData = ref<ThirdPartyOpenAuthType>({
  thirdPartyOpenAuthDataId: '',
  type: '',
  status: '',
  generationTime: '',
  extra: {
    link_url: ''
  }
})

const dataIntegrationOptions = computed(() => {
  return props.platformData.platformProviderMapList
})

const currentPlatformProviderMap = computed<PlatformProviderMapType>(() => {
  return (
    find(dataIntegrationOptions.value, { providerId: accountForm.providerId || '' }) ?? ({} as PlatformProviderMapType)
  )
})

const currentProvider = computed<PlatformProviderType>(() => {
  return currentPlatformProviderMap.value?.provider ?? ({} as PlatformProviderType)
})

const masterAccountOptions = computed(() => {
  const currentPlatform = accountList.value.filter(
    (item: any) => item.platformId === props.platformData.platformId && item.accountLevel === 'MASTER'
  )
  const options = currentPlatform.map((accountItem: any) => {
    return {
      id: accountItem.entityAccountId,
      value: accountItem.entityAccountId,
      label: accountItem.name
    }
  })
  return props.model === 'edit' ? options.filter((item: any) => item.id !== props.currentData.entityAccountId) : options
})

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Account Name is required'
  },
  platformId: {
    required: true,
    trigger: 'blur',
    message: 'Exchange is required'
  },
  'transactionOption.operator': {
    required: true,
    trigger: 'blur',
    message: 'Transaction data is required'
  },
  providerId: {
    required: true,
    trigger: 'blur',
    validator: (rule: any, value: any, callback: any) => {
      if (
        currentPlatformProviderMap.value?.authType !== null &&
        thirdPartyOpenAuthTypeData.value.status !== 'COMPLETED'
      ) {
        callback(new Error(''))
      } else {
        callback()
      }
    }
  },
  value: {
    required: true,
    trigger: 'blur',
    validator: (rule: any, value: any, callback: any) => {
      if (accountForm.transactionOption.operator === 'BETWEEN') {
        if (
          (accountForm.transactionOption.value.length < 2 && !accountForm.transactionOption.value[0],
          !accountForm.transactionOption.value[1])
        ) {
          callback(new Error('Date is required'))
        } else if ((accountForm.transactionOption.value[0], accountForm.transactionOption.value[1])) {
          if (dayjs(accountForm.transactionOption.value[0]).isAfter(accountForm.transactionOption.value[1])) {
            callback(new Error('Start date must be less than end date'))
          } else {
            callback()
          }
        }
      } else if (accountForm.transactionOption.operator !== 'BETWEEN' && !accountForm.transactionOption.value[0]) {
        callback(new Error('Date is required'))
      } else {
        callback()
      }
    }
  }
})

const integrationInfo: any = useComputedHook((integration: any) => {
  let info = ''
  if (integration.withBalance && integration.withTransaction) {
    info = 'Transaction and Balance'
  } else if (integration.withBalance) {
    info = 'Balance Only'
  } else if (integration.provider.slug === 'csv' || integration.withTransaction) {
    info = 'Transaction Only'
  }
  return info
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const disabledSave = computed(() => {
  if (props.model === 'add') {
    if (!accountForm.name || !accountForm.platformId) return true
    if (props.platformData.enableMasterSubAccount && !accountForm.accountLevel) return true
    if (['WALLET', 'BANK'].includes(accountForm.type) && !accountForm.identity) return true
    if (accountForm.type !== 'CSV') {
      if (!Array.isArray(currentPlatformProviderMap.value?.config)) return true
      if (
        currentPlatformProviderMap.value?.config?.some(
          (config: any) => config?.config?.validator?.required && !accountForm[config?.key]
        )
      )
        return true
      if (accountForm.transactionOption.operator === 'BETWEEN') {
        if (!accountForm.transactionOption.value[0] || !accountForm.transactionOption.value[1]) return true
      } else if (accountForm.transactionOption.operator !== 'ALL' && !accountForm.transactionOption.value[0]) {
        return true
      }
    }
  } else if (props.model === 'edit') {
    let isSameAccountLevel = false
    if (props.platformData.enableMasterSubAccount) {
      const currentMasterId =
        props.currentData.masterEntityAccountId === '0' ? '' : props.currentData?.masterEntityAccountId || ''
      if (
        accountForm.accountLevel === props.currentData.accountLevel &&
        accountForm.masterEntityAccountId === currentMasterId
      ) {
        isSameAccountLevel = true
      }
    }
    if (
      accountForm.name === props.currentData.name &&
      accountForm.transactionOption.operator === props.currentData?.transactionOption?.operator &&
      isSameAccountLevel
    ) {
      return true
    }
  }
  return false
})

const emit = defineEmits(['resetList', 'remoteMethod'])

const onCloseDialog = () => {
  accountForm.auxiliaryValueList = []
  showAuxiliaryCodeSelect.value = false
  accountFormRef.value?.resetFields()
}

const fetchSourceAuthDetail = async () => {
  try {
    authLoading.value = true
    const { data } = await AccountsApi.getSourceAuthDetail(
      entityId.value,
      thirdPartyOpenAuthTypeData.value.thirdPartyOpenAuthDataId
    )
    thirdPartyOpenAuthTypeData.value.status = data.status
    accountFormRef.value?.validateField('providerId')
    if (data.status === 'COMPLETED') {
      ElMessage.success('Authenticate successfully')
    } else {
      ElMessage.error('Authenticate failed')
    }
  } catch (error) {
    console.log(error)
  } finally {
    authLoading.value = false
  }
}

const receiveMessage = (message: any) => {
  if (message.data === 'close') {
    showSourceAuthDialog.value = false
    authLoading.value = false
    window.removeEventListener('message', receiveMessage)
    fetchSourceAuthDetail()
  }
  if (message.data === 'success') {
    authLoading.value = false
    showSourceAuthDialog.value = false
    window.removeEventListener('message', receiveMessage)
    fetchSourceAuthDetail()
  }
}

const applyOpenAuthInfo = async () => {
  try {
    authLoading.value = true
    if (
      !thirdPartyOpenAuthTypeData.value.thirdPartyOpenAuthDataId ||
      thirdPartyOpenAuthTypeData.value.status !== 'COMPLETE'
    ) {
      const { data } = await AccountsApi.applySourceAuth(entityId.value, {
        type: currentPlatformProviderMap.value?.authType
      })
      thirdPartyOpenAuthTypeData.value = data
    }
    showSourceAuthDialog.value = true
    nextTick(() => {
      window.addEventListener('message', receiveMessage, false)
    })
  } catch (error: any) {
    console.log(error)
  }
}

const onChangeOperator = () => {
  accountForm.transactionOption.value = []
}

const onChangeDataIntegration = () => {
  accountForm.type = currentProvider.value.slug === 'csv' ? 'CSV' : props.platformData.type
  accountForm.type = accountForm.type === 'CHAIN' ? 'WALLET' : accountForm.type
}

const onOpenStartDateTime = () => {
  visibleStartPopover.value = !visibleStartPopover.value
  startArrowRotate.value = visibleStartPopover.value ? 'rotate(-90deg)' : 'rotate(90deg)'
  if (visibleStartPopover.value) {
    unref(datePickerStartPopoverRef)?.handleOpen?.()
  } else {
    unref(datePickerStartPopoverRef)?.handleClose?.()
  }
}

const onCloseStartDateTime = () => {
  visibleStartPopover.value = false
  unref(datePickerStartPopoverRef)?.handleClose?.()
  startArrowRotate.value = 'rotate(90deg)'
}

const onOpenEndDateTime = () => {
  visibleEndPopover.value = !visibleEndPopover.value
  endArrowRotate.value = visibleEndPopover.value ? 'rotate(-90deg)' : 'rotate(90deg)'
  if (visibleEndPopover.value) {
    unref(datePickerEndPopoverRef)?.handleOpen?.()
  } else {
    unref(datePickerEndPopoverRef)?.handleClose?.()
  }
}

const onAddAuxiliaryCode = () => {
  showAuxiliaryCodeSelect.value = true
  accountForm.auxiliaryValueList.push({
    index: accountForm.auxiliaryValueList?.length,
    auxiliaryTypeId: ''
  })
}

const onChangeAuxiliaryType = (auxiliaryTypeId: string, index: number) => {
  const auxiliaryValue = props.auxiliaryCodeOptionData.list.find(
    (item: any) => item.auxiliaryTypeId === auxiliaryTypeId
  )
  const item: any = {
    auxiliaryTypeId,
    type: auxiliaryValue.type === 'ITEM' ? 'AUXILIARY_ITEM' : 'COUNTERPARTY',
    index
  }
  if (item.type === 'COUNTERPARTY') {
    item.counterpartyId = ''
  } else {
    item.auxiliaryItemId = ''
    item.auxiliaryItems = auxiliaryValue.auxiliaryItems
  }
  accountForm.auxiliaryValueList[index] = item
}

const onDeleteAuxiliaryCode = (index: number) => {
  accountForm.auxiliaryValueList.splice(index, 1)
  accountForm.auxiliaryValueList.forEach((item: any, i: number) => {
    item.index = i
  })
}

const remoteCounterpartyMethod = async (query: string) => {
  if (query) {
    try {
      searchCounterpartyLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list.filter((item: any) => item.type !== 'FEE')
    } catch (error) {
      console.log(error)
    } finally {
      searchCounterpartyLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
  }
}

const onCloseEndDateTime = () => {
  visibleEndPopover.value = false
  unref(datePickerEndPopoverRef)?.handleClose?.()
  endArrowRotate.value = 'rotate(90deg)'
}

const onChangeDate = (scope: string) => {
  if (scope === 'start') {
    visibleStartPopover.value = false
    startArrowRotate.value = 'rotate(90deg)'
  } else {
    visibleEndPopover.value = false
    endArrowRotate.value = 'rotate(90deg)'
  }
  accountFormRef.value?.validateField('value')
}

const onEditAccount = async (data: object) => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.entityAccount?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    const pickInfo = props.platformData.enableMasterSubAccount
      ? ['name', 'memo', 'auxiliaryValueList', 'accountLevel', 'masterEntityAccountId']
      : ['name', 'memo', 'auxiliaryValueList']
    const params: any = pick(data, pickInfo)
    params.name = trim(params.name)
    await AccountsApi.editEntityAccount(entityId.value, props.currentData?.entityAccountId, params)
    // eslint-disable-next-line consistent-return
    return Promise.resolve()
  } catch (error: any) {
    console.log(error)
    // eslint-disable-next-line consistent-return
    return Promise.reject(error)
  }
}

const onAddAccount = async (data: any): Promise<any> => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.entityAccount?.create
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    const accountParams: any = {
      name: trim(data.name),
      memo: data.memo,
      platformId: data.platformId,
      providerId: data.providerId,
      auxiliaryValueList: data.auxiliaryValueList,
      ...(props.platformData.enableMasterSubAccount
        ? {
            accountLevel: data.accountLevel,
            masterEntityAccountId: data.masterEntityAccountId
          }
        : {})
    }
    if (currentProvider.value.slug !== 'csv') {
      const source: any = {
        transactionOption: data.transactionOption,
        autoVerify: data.autoVerify,
        configValues: []
      }
      if (currentPlatformProviderMap.value?.config?.length) {
        currentPlatformProviderMap.value?.config?.forEach((config: any) => {
          if (
            !config?.config?.validator?.required &&
            ((config?.config?.validator?.type === 'string' && !accountForm[config.key]) ||
              (config?.config?.validator?.type === 'array' && !accountForm[config.key].length))
          ) {
            return
          }
          source.configValues.push({
            key: config.key,
            value: accountForm[config.key]
          })
        })
      }
      if (
        currentPlatformProviderMap.value?.authType !== null &&
        thirdPartyOpenAuthTypeData.value.status === 'COMPLETED'
      ) {
        source.thirdPartyOpenAuthDataId = thirdPartyOpenAuthTypeData.value.thirdPartyOpenAuthDataId
      }
      accountParams.source = source
    }

    if (props.platformData.type === 'BANK' || props.platformData.type === 'CHAIN') {
      accountParams.identity = accountForm.identity
    }
    const response = await AccountsApi.createEntityAccount(entityId.value, accountParams)
    // eslint-disable-next-line consistent-return
    return Promise.resolve(response.data)
  } catch (error) {
    console.log(error)
    // eslint-disable-next-line consistent-return
    return Promise.reject(error)
  }
}

const onClickConnect = async () => {
  if (!accountFormRef.value) return
  await accountFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        let pickInfo = ['type', 'name', 'platformId', 'providerId', 'autoVerify', 'auxiliaryValueList', 'memo']
        if (props.platformData.enableMasterSubAccount) {
          pickInfo = [...pickInfo, 'accountLevel', 'masterEntityAccountId']
        }
        const data: any = pick(accountForm, pickInfo)
        data.auxiliaryValueList = data.auxiliaryValueList
          .filter(
            (item: any) =>
              item.auxiliaryTypeId &&
              ((item.type === 'COUNTERPARTY' && item.counterpartyId) ||
                (item.type === 'AUXILIARY_ITEM' && item.auxiliaryItemId))
          )
          .map((item: any) => {
            return {
              auxiliaryTypeId: item.auxiliaryTypeId,
              counterpartyId: item?.counterpartyId,
              auxiliaryItemId: item?.auxiliaryItemId
            }
          })
        if (
          (props.model === 'edit' &&
            accountForm.transactionOption.operator !== props.currentData.transactionOption?.operator) ||
          props.model === 'add'
        ) {
          data.transactionOption = {
            operator: accountForm.transactionOption.operator,
            value: accountForm.transactionOption.operator === 'ALL' ? null : accountForm.transactionOption.value
          }
        }
        if (props.platformData.enableMasterSubAccount) {
          if (!data?.masterEntityAccountId || data?.accountLevel === 'MASTER') {
            data.masterEntityAccountId = '0'
          }
        }
        if (props.model === 'edit') {
          await onEditAccount(data)
        } else {
          await onAddAccount(data)
        }
        if (props.platformData.enableMasterSubAccount) {
          accountStore.fetchEntityAccountList(entityId.value, { withDeleted: false })
        }
        accountFormRef.value?.resetFields()
        ElMessage.success(t('message.saveSuccess'))
        emit('resetList')
        emitter.emit('resetAccountList')
        onCloseDialog()
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

defineExpose({ onResetFields: onCloseDialog })

watch(
  [() => props.currentData, () => props.loading, () => props.showDialog, () => props.platformData],
  async () => {
    if (
      !props.loading &&
      props.showDialog &&
      !isEmpty(props.platformData) &&
      (!isEmpty(props.currentData) || props.model === 'add')
    ) {
      accountForm.platformId = props.platformData.platformId
      accountForm.type =
        // eslint-disable-next-line no-nested-ternary
        (props.platformData.csvDriver !== 'NONE' && props.platformData.collectorDriver !== 'NONE') ||
        props.platformData.collectorDriver === 'CUSTOM'
          ? props.platformData.type
          : 'CSV'
      accountForm.type = accountForm.type === 'CHAIN' ? 'WALLET' : accountForm.type
      accountForm.providerId =
        (props.platformData.csvDriver !== 'NONE' && props.platformData.collectorDriver !== 'NONE') ||
        props.platformData.collectorDriver === 'CUSTOM'
          ? find(dataIntegrationOptions.value, (item: any) => item.provider.slug !== 'csv')?.providerId
          : find(dataIntegrationOptions.value, (item: any) => item.provider.slug === 'csv')?.providerId
      if (props.model === 'edit' && props.currentData) {
        accountForm.name = props.currentData?.name
        accountForm.memo = props.currentData?.memo
        if (props.currentData?.auxiliaryValueList.length) {
          accountForm.auxiliaryValueList = props.currentData?.auxiliaryValueList.map((item: any, index: number) => {
            const auxiliaryValue = props.auxiliaryCodeOptionData.list.find(
              (auxiliaryType: any) => auxiliaryType.auxiliaryTypeId === item.auxiliaryTypeId
            )
            return {
              index,
              auxiliaryTypeId: item.auxiliaryTypeId,
              counterpartyId: item?.counterpartyId ?? '',
              auxiliaryItemId: item?.auxiliaryItemId ?? '',
              auxiliaryItems: auxiliaryValue?.auxiliaryItems ?? [],
              type: item.counterpartyId ? 'COUNTERPARTY' : 'AUXILIARY_ITEM'
            }
          })
          const counterpartyData = props.currentData?.auxiliaryValueList
            .filter((item: any) => item?.auxiliaryType.type === 'COUNTERPARTY')
            .map((item: any) => item.counterparty)
          const list = [...cloneDeep(originCounterpartyOptions.value), ...cloneDeep(counterpartyData)]
          originCounterpartyOptions.value = uniqBy(list, 'counterpartyId')
          counterpartyOptions.value = originCounterpartyOptions.value
        }
        if (accountForm.auxiliaryValueList.length) {
          showAuxiliaryCodeSelect.value = true
        }
        if (accountForm.type === 'WALLET' || props.platformData.type === 'CHAIN') {
          accountForm.identity = props.currentData.identity
        }
        if (props.platformData.type === 'BANK') {
          accountForm.identity = props.currentData.identity
        }
        accountForm.accountLevel = props.currentData?.accountLevel || ''
        const masterAccountId = props.currentData?.masterEntityAccount?.entityAccountId
        accountForm.masterEntityAccountId =
          isEmpty(props.currentData?.masterEntityAccount) || masterAccountId === '0' ? '' : masterAccountId
      }
    }
  },
  { immediate: true }
)

watch(
  () => currentPlatformProviderMap.value,
  () => {
    if (!isEmpty(props.platformData)) {
      if (currentPlatformProviderMap.value?.config?.length) {
        currentPlatformProviderMap.value?.config.forEach((config: any) => {
          accountForm[config?.key] = config?.type === 'checkbox' ? [] : ''
          if (config?.config?.validator?.required) {
            rules[config?.key] = {
              required: true,
              trigger: 'blur',
              message: `${config?.title} is required`
            }
          }
        })
      }
      if (accountForm.type === 'WALLET' || props.platformData.type === 'CHAIN') {
        accountForm.identity = props.model === 'edit' ? props.currentData.identity : ''
        rules.identity = {
          required: true,
          trigger: 'blur',
          message: 'Address is required'
        }
      }
      if (props.platformData.type === 'BANK') {
        accountForm.identity = props.model === 'edit' ? props.currentData.identity : ''
        rules.identity = {
          required: true,
          trigger: 'blur',
          message: t('message.pleaseEnterAccountNo')
        }
      }
      setTimeout(() => {
        accountFormRef.value?.clearValidate()
      }, 10)
    }
  },
  { immediate: true }
)

watch(
  [() => props.counterpartyList, () => props.showDialog],
  () => {
    if (props.showDialog) {
      const list = [...cloneDeep(originCounterpartyOptions.value), ...cloneDeep(props.counterpartyList)]
      originCounterpartyOptions.value = uniqBy(list, 'counterpartyId')
      counterpartyOptions.value = originCounterpartyOptions.value
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-account-data-item-auxiliary-code__item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .elv-account-data-item-auxiliary-code-select {
    width: fit-content !important;

    .el-select__wrapper {
      width: 269px;
    }
  }

  .elv-account-data-item-auxiliary-code-select__delete {
    fill: #838d95;

    &.is-disabled {
      cursor: not-allowed;
      fill: #c4c9d1;
    }

    &:not(.is-disabled):hover {
      cursor: pointer;
      fill: #1343bf;
    }
  }
}

.elv-ledger-coa-form-item__auxiliaryCode-add {
  display: flex;
  align-items: center;
  color: #1753eb;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &:not(.is-disabled):hover {
    cursor: pointer;
  }

  .is-disabled {
    cursor: not-allowed;
  }

  svg {
    margin-right: 4px;
  }
}

.elv-account-data-integration-error {
  display: flex;
  align-items: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 9px;

  svg {
    margin-right: 4px;
  }

  &.is-error {
    color: #eb3a30;
  }

  &.is-success {
    color: #749a01;
  }
}

.elv-accounts-dialog-form-account-level-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .el-radio {
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 400;
    color: #1e2024;
    height: 28px;

    .el-radio__label {
      display: flex;
      align-items: center;
      height: 28px;
    }

    .el-radio__input {
      .el-radio__inner:hover {
        border-color: #1753eb;
      }

      &.is-checked {
        .el-radio__inner {
          background: #1753eb;
          border-color: #1753eb;
        }
      }
    }

    &.is-checked {
      .el-radio__label {
        color: #1e2024;
      }
    }
  }
}

.elv-accounts-dialog-form-master-account-select {
  margin-top: 8px;
}
</style>
